window.jsyaml = require('js-yaml');

window.MarkdownIt = require('markdown-it');
window.feather = require('feather-icons');
window.htmlparser2 = require('htmlparser2');
window.ResizeSensor = require('css-element-queries/src/ResizeSensor');

import Vue from 'vue';
window.Vue = Vue;

import PortalVue from 'portal-vue';
Vue.use(PortalVue);
window.PortalVue = PortalVue;

import './vendor/jquery.ui.touch-punch.js';

Vue.component('grid-layout', require('vue-grid-layout').GridLayout);
Vue.component('grid-item', require('vue-grid-layout').GridItem);
